<!--
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-10-24 09:10:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/About.vue
-->
<template>
  <div class="about">
    <h3 v-if="(auth.state == 0)">微信身份验证中</h3>
    <div v-else>
      <v-card class="ma-5">
        <v-card-title primary-title> 未与微信绑定 </v-card-title>
        <v-card-text class="subtitle-1">
          系统未发现你的微信账号，可能还未进行绑定或注册。<br />
          假如你已经是系统注册用户，你只需在微信端登录，并确保“与微信账号绑定”被选取，就能达到微信端免密码登录的便捷服务。<br />
          假如你还未注册，只需要在登录界面点击“注册”按钮，注册时会自动绑定你的微信账号。
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="$router.push({ path: '/login' })"
            >跳转到登录页面</v-btn
          >
        </v-card-actions>
      </v-card>
    </div>
    <!--
    <h6>wechat-url:{{ url }}</h6>
    <h6>code:{{ code }}</h6>
    <h6>urls:{{ urls }}</h6>

    <h6>sid:{{ wechat.sid }}</h6>
    <h6>openid:{{ wechat.openid }}</h6>
    <h6>wxopenid-opention:{{ opention }}</h6>
    <h6>wxopenid:{{ wxopenid }}</h6>
    <h6>cookieOpenid:{{ cookieOpenid }}</h6>
    <div>
      <vue-qr ref="Qrcode" :text="urlsCode" qid="testQrId"></vue-qr>
    </div>
    -->
  </div>
</template>
<script>
//import VueQr from "vue-qr";

export default {
  //components: { VueQr },
  data() {
    return {
      url: "",
      urls: "",
      state: "",
      op: "",
      code: "",
      scode: "",
      urlsCode: "",

      wechat: {
        openid: "",
        sid: "",
      },
      opention: {},
      wxopenid: "",

      auth: {
        state: 0,
      },
      cookieOpenid: {},
    };
  },
  created() {
    this.urlsCode = window.location.href;
    this.urls = this.$route.query;
    this.state = this.$route.query.state;
    this.op = this.$route.query.OP;
    this.code = this.$route.query.code;
  },
  mounted() {
    this.uscode();
  },
  methods: {
    uscode() {
      var data = {
        module: "wechat",
        title: "uscode",
        data: {
          appid: this.op,
          code: this.code,
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then((res) => {
        if (res.data.wechat) {
          if (res.data.wechat && res.data.wechat.openid) {
            that.wechat = res.data.wechat;
            that.wechatLogin();
          }
        }
      });
    },
    wechatLogin() {
      var data = {
        module: "user",
        title: "wxopenid2",
        data: {
          appid: this.wechat.sid,
          openid: this.wechat.openid,
          asid: this.$route.query.sid ? this.$route.query.sid : "",
          aopenid: this.$route.query.openid ? this.$route.query.openid : "",
        },
      };
      let that = this;
      this.opention = data;
      this.$sopen.requestUscenterApi(data).then((res) => {
        that.wxopenid = res.data.user;
        if (res.data.user.errcode == 0 && that.wxopenid.guzi && that.wxopenid.guzi > 0) {
          //当微信OPENID已经注册，而且谷子科技已经注册
          if (that.wxopenid["cookie"] != undefined) {
            this.$sopen.setCookie("usCookie", res.data.user.cookie);
            that.reusrec();
            that.auth.state = 0;
          } else if (
            this.$route.query.openid &&
            this.$route.query.openid != undefined
          ) {
            that.auth.state = 1;
            let cookieOpenid = {};
            cookieOpenid[this.wechat.sid] = this.wechat.openid;
            if (this.$route.query.openid)
              cookieOpenid[this.$route.query.sid] = this.$route.query.openid;
            that.cookieOpenid = cookieOpenid;
            localStorage.setItem("weChatOpenid", JSON.stringify(cookieOpenid));
          }
        } else if (res.data.user.errcode == 0) {
          //当微信OPENID已经注册，但是谷子科技没有注册
          let redirect_uri = encodeURIComponent(
            window.location.protocol +
              "//" +
              window.location.host +
              "/weChat/oauth2?OP=1008&sid=" +
              that.wechat.sid +
              "&openid=" +
              that.wechat.openid
          );

          that.url =
            "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf99fad9253c6594d&redirect_uri=" +
            redirect_uri +
            "&response_type=code&scope=snsapi_base&state=" +
            encodeURIComponent(that.state) +
            "#wechat_redirect";
          window.location.href = that.url;
        } else {
          //没有发现学校OPENID的注册信息的时候
          if (this.wechat.sid != "1008") {
            //假如不是谷子科技的认证，检测谷子科技是否有微信注册信息
            let redirect_uri = encodeURIComponent(
              window.location.protocol +
                "//" +
                window.location.host +
                "/weChat/oauth2?OP=1008&sid=" +
                that.wechat.sid +
                "&openid=" +
                that.wechat.openid
            );
            that.url =
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf99fad9253c6594d&redirect_uri=" +
              redirect_uri +
              "&response_type=code&scope=snsapi_base&state=" +
              encodeURIComponent(that.state) +
              "#wechat_redirect";
            window.location.href = that.url;
          } else {
            that.auth.state = 1;
            let cookieOpenid = {};
            cookieOpenid[this.wechat.sid] = this.wechat.openid;
            if (this.$route.query.openid)
              cookieOpenid[this.$route.query.sid] = this.$route.query.openid;
            that.cookieOpenid = cookieOpenid;
            localStorage.setItem("weChatOpenid", JSON.stringify(cookieOpenid));
          }
        }
      });
    },
    reusrec(targetid = "") {
      let tokenValue = this.$sopen.getCookie("usCookie");
      let data = {
        module: "usinfo",
        title: "reusrec",
        data: {
          cookie: tokenValue,
          targetid: targetid,
          url:
            document.location.protocol +
            "//" +
            (window.location.host.indexOf("schoolopen") > -1
              ? window.location.host
              : "luohu.sopen.cn"),
        },
      };
      let that = this;
      this.$sopen.requestUscenterApi(data).then(function (res) {
        if (res.data.usinfo.usinfo.TARGETID != "") {
          that.$router.push({
            path: "/token",
            query: {
              openuid: res.data.usinfo.openuid,
              state: encodeURIComponent(that.state),
            },
          });
        } else {
          that.$router.push({
            path: "/user",
          });
        }
      });
    },
  },
};
</script>